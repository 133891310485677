[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
  border: none;
}

[data-amplify-authenticator][data-variation="modal"] {
  background-color: transparent;
  padding: 10px;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0;
}

.amplify-tabs {
  border: none;
}

.amplify-tabs-item {
  padding-bottom: 24px;
}

.amplify-tabs-item[data-state="active"] {
  color: #000;
  border: none;
  text-decoration: underline;
  text-underline-position: under;
}

.amplify-tabs-item[data-state="inactive"] {
  color: #000;
  background-color: transparent;
  border: none;
}

.amplify-button--small {
  font-size: 13px;
}

.amplify-input {
  color: #000;
}

.amplify-input::placeholder {
  color: #8f95b2;
}

.amplify-input,
.amplify-button {
  border-top-color: #8f95b2;
  border-bottom-color: #8f95b2;
  border-left-color: #8f95b2;
  border-right-color: #8f95b2;
}

.amplify-button--primary,
.amplify-button--primary:hover {
  background-color: #000;
  border: none;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333;
  --bs-btn-hover-border-color: #333;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333;
  --bs-btn-active-border-color: #333;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000;
}

.btn-outline-primary {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none;
}

.btn-success {
  --bs-btn-color: #e5dfd9;
  --bs-btn-bg: #2b2b2b;
  --bs-btn-border-color: #2b2b2b;
  --bs-btn-hover-color: #e5dfd9;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #e5dfd9;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e5dfd9;
  --bs-btn-disabled-bg: #2b2b2b;
  --bs-btn-disabled-border-color: #2b2b2b;
}

.btn-outline-success {
  --bs-btn-color: #2b2b2b;
  --bs-btn-border-color: #2b2b2b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2b2b;
  --bs-btn-hover-border-color: #2b2b2b;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2b2b2b;
  --bs-btn-active-border-color: #2b2b2b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2b2b2b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2b2b2b;
  --bs-gradient: none;
}

.bg-primary {
  background-color: #000 !important;
}

.bg-success {
  background-color: #2b2b2b !important;
}

.bg-info {
  background-color: #c4c4c4 !important;
}
